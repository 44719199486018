import ReactDOM from 'react-dom/client'
import App from './App'


import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ADDRESS } from './utils/config'

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('tw-user-token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    }
  }
})

const httpLink = createHttpLink({
  uri: ADDRESS,
  credentials: 'same-origin',
  //credentials: 'include'
  //credentials: 'same-origin' if your backend server is the same domain
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
})

ReactDOM.createRoot(document.getElementById('root') as Element).render(
  <ApolloProvider client={client}>
    <App/> 
  </ApolloProvider>
)
