import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $setBlocksType } from '@lexical/selection';
import type { EditorConfig, LexicalNode, NodeKey, RangeSelection } from 'lexical';
import { $createParagraphNode, $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, ElementNode, createCommand } from 'lexical';

export class BannerNode extends ElementNode {
    static getType(): string {
        return 'banner';
    }
    constructor(key?: NodeKey) {
        super();
    }
    static clone(node: BannerNode): BannerNode {
        return new BannerNode(node._key);
    }

    createDOM(config: EditorConfig): HTMLElement {
        const element = document.createElement('div');
        element.className = config.theme.banner;
        return element;
    }

    updateDOM(): false {
        return false;
    }

    insertNewAfter(selection: RangeSelection, restoreSelection?: boolean | undefined): LexicalNode | null {
        const newElement = $createParagraphNode();
        const direction = this.getDirection();
        newElement.setDirection(direction);
        this.insertAfter(newElement, restoreSelection);
        return newElement;
    }
    collapseAtStart(): boolean {
        const paragraph = $createParagraphNode();
        const children = this.getChildren();
        children.forEach(child => paragraph.append(child));
        this.replace(paragraph);
        return true;
    }
}

export function $createBannerNode():BannerNode {
    return new BannerNode();
}

export function $isBannerNode(node: LexicalNode): node is BannerNode {
    return node instanceof BannerNode;
}

export const INSERT_BANNER_COMMAND = createCommand('insertBanner');

export function BannerPlugin(): null {
    const [editor] = useLexicalComposerContext();
    if(!editor.hasNodes([BannerNode])) {
        throw new Error('BannerPlugin: BannerNode not registered on editor');
    }
    editor.registerCommand(INSERT_BANNER_COMMAND , () => {
        const selection = $getSelection();
        if($isRangeSelection(selection)) {
            $setBlocksType(selection, $createBannerNode);
        }
        return true;
    }, COMMAND_PRIORITY_LOW);
    return null;
}
