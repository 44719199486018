import { useRef, useEffect, SyntheticEvent, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Editor } from './Editor/Editor';

import { $getRoot, LexicalEditor } from 'lexical';
import { AppType } from '../types';
import { ADD_APP_REF, DELETE_APP_REF, EDIT_APP, EDIT_APP_ALTERNATIVE, EDIT_APP_COUNTER, EDIT_APP_REF, EDIT_SIMILAR_APP } from '../queries';
import ConnectAppToPrinciple from './ConnectAppToPrinciple';

const EditApp = ({ setNotification, app, setOpenEditor }:
  { setNotification: (message: string, type: 'success' | 'error') => void, app: AppType, setOpenEditor: React.Dispatch<React.SetStateAction<boolean>> }) => {
  //const [koreanName, setKoreanName] = useState('')
  //const [phone, setPhone] = useState('')
  const [alternative, setAlternative] = useState('');
  const [countermeasure, setCountermeasure] = useState('');
  const [similarApp, setSimilarApp] = useState('');

  const [author, setAuthor] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [linkTitle, setLinkTitle] = useState('');
  const [appRefId, setAppRefId] = useState('');

  const [editApp, result] = useMutation(EDIT_APP, {
    onError: (error) => {
      //const messages = error.graphQLErrors[0].message
      setNotification(error.message, 'error');
    },
  })
  const editorRef = useRef<LexicalEditor | null>(null);

  const [editAppAlternative] = useMutation(EDIT_APP_ALTERNATIVE, {
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })

  const [editAppCounter] = useMutation(EDIT_APP_COUNTER, {
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })

  const [editSimilarApp] = useMutation(EDIT_SIMILAR_APP, {
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })

  const [addAppRef] = useMutation(ADD_APP_REF, {
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })

  const [editAppRef] = useMutation(EDIT_APP_REF, {
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })

  const [deleteAppRef] = useMutation(DELETE_APP_REF, {
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })

  const submit = (event: SyntheticEvent) => {
    event.preventDefault()
    const latestEditorState = editorRef.current?.getEditorState();
    const textContent = latestEditorState?.read(() =>
      $getRoot().getTextContent()
    );
    const description = textContent;

    editApp({ variables: { id: app.id, description: description } })
    if (!result.error) {
      setOpenEditor(false);
    }
    setNotification('추가되었습니다.', 'success');
  }

  const submitAlternative = (event: SyntheticEvent) => {
    event.preventDefault()

    editAppAlternative({ variables: { id: app.id, alternative: alternative } })

    setNotification('수정되었습니다.', 'success');
  }

  const submitCountermeasure = (event: SyntheticEvent) => {
    event.preventDefault()

    editAppCounter({ variables: { id: app.id, countermeasure: countermeasure } })

    setNotification('수정되었습니다.', 'success');
  }

  const submitSimilarApp= (event: SyntheticEvent) => {
    event.preventDefault()

    editSimilarApp({ variables: { id: app.id, similarApp: similarApp } })

    setNotification('수정되었습니다.', 'success');
  }

  const submitAppRef = async (event: SyntheticEvent) => {
    event.preventDefault()

    const result = await addAppRef
      ({
        variables: {
          author: author, title: title, description: description,
          link: link, linkTitle: linkTitle, appId: app.id
        }
      })

    if (result.data) setNotification('추가되었습니다.', 'success');
  }

  const setEditAppRef = async (ref: any) => {
    // event.preventDefault()
    setAuthor(ref.author);
    setTitle(ref.title);
    setDescription(ref.description);
    setLink(ref.link);
    setLinkTitle(ref.linkTitle);
    setAppRefId(ref.id)
  }

  const submitEditAppRef = async (event: SyntheticEvent) => {
    event.preventDefault()

    const result = await editAppRef
      ({
        variables: {
          author: author, title: title, description: description,
          link: link, linkTitle: linkTitle, id: appRefId
        }
      })

    if (result.data) setNotification('수정되었습니다.', 'success');
  }

  const submitDeleteAppRef = async (id: number) => {
    const result = await deleteAppRef
      ({
        variables: {
        id: id
        }
      })

      if (result.data) setNotification('삭제되었습니다.', 'success');
  }

  useEffect(() => {
    if (result.data && result.data.editDescription === null) {
      setNotification('Not Found', 'error')
    }
  }, [result.data]) // eslint-disable-line 

  useEffect(() => {
    // Fetch the existing value from your data source (e.g., an API call or from props)
    const existingValue = app.countermeasure; // Replace with your logic to fetch the value

    // Set the existing value to the state if it exists
    if (existingValue) {
      setCountermeasure(existingValue);
    }
  }, [app.countermeasure]);
  return (
    <div>
      <h2>{app.names.koreanName} 설명 추가하기</h2>

      <form onSubmit={submit}>
        <div>
          {/* <input
            value={koreanName}
            onChange={({ target }) => setKoreanName(target.value)}
          /> */}
        </div>
        <div className="editorWrapper">
          {/* <input
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
          /> */}
          <Editor ref={editorRef} description={app.description} />
        </div>
        <button type='submit'>수정</button>
      </form>
      <button type='button' onClick={() => setOpenEditor(false)}>취소</button>
      <ConnectAppToPrinciple setNotification={setNotification} appId={app.id} />
      <hr />
      <form onSubmit={submitCountermeasure}>
        <input id="counterMeasure" type="text" onChange={c => setCountermeasure(c.currentTarget.value)}
          value={countermeasure}
        ></input>
        <button type='submit'>
          대처법 수정</button>
      </form>
      <form onSubmit={submitAlternative}>
        <input type="text" onChange={c => setAlternative(c.currentTarget.value)}></input>
        <button type='submit'>
          대안 수정</button>
      </form>
      <form onSubmit={submitSimilarApp}>
        <input type="text" onChange={c => setSimilarApp(c.currentTarget.value)}></input>
        <button type='submit'>
          비슷한 앱 수정</button>
      </form>
      <hr />
      <form onSubmit={submitAppRef}>
        <div>author<input type="text" onChange={c => setAuthor(c.currentTarget.value)}></input></div>
        <div>title<input type="text" onChange={c => setTitle(c.currentTarget.value)}></input></div>
        <div>description<input type="text" onChange={c => setDescription(c.currentTarget.value)}></input></div>
        <div>link<input type="text" onChange={c => setLink(c.currentTarget.value)}></input></div>
        <div>linkTitle<input type="text" onChange={c => setLinkTitle(c.currentTarget.value)}></input></div>
        <button type='submit'>
          참고자료 올리기</button>
      </form>
      {app.app_refs.map((ref) => {
        return <div key={ref.id}><button onClick={() => setEditAppRef(ref)}>{ref.title + " | " + ref.author + " | " + ref.description
         + " | " + ref.link + " | " + ref.linkTitle}</button></div>
      })}
      <form onSubmit={submitEditAppRef}>
        <div>author<input 
        value={author}
        type="text" onChange={c => setAuthor(c.currentTarget.value)}></input></div>
        <div>title<input 
        value={title}
        type="text" onChange={c => setTitle(c.currentTarget.value)}></input></div>
        <div>description<input 
        value={description}
        type="text" onChange={c => setDescription(c.currentTarget.value)}></input></div>
        <div>link<input 
        value={link}
        type="text" onChange={c => setLink(c.currentTarget.value)}></input></div>
        <div>linkTitle<input 
        value={linkTitle}
        type="text" onChange={c => setLinkTitle(c.currentTarget.value)}></input></div>
        <div>appRefId<input 
        value={appRefId}
        type="text" onChange={c => setAppRefId(c.currentTarget.value)}></input></div>
        <button type='submit'>
          참고자료 수정하기</button>
      </form>

      {/* {app.app_refs.map((ref) => {
        return <div key={ref.id}><button onClick={() => submitDeleteAppRef(ref.id)}>{ref.title + " | " + ref.author + " | " + ref.description
         + " | " + ref.link + " | " + ref.linkTitle}-x</button></div>
      })} */}

    </div>
  )
}

export default EditApp;