const Loading = () => {
    return (
        <div style={{ marginTop: 30, color: "#7c73e6" }}>
            잠시만 기다려주세요
        </div>
    );
}

export default Loading;


 //const [showMessage, setShowMessage] = useState(false);

// useEffect(() => {
//     const timer = setTimeout(() => {
//         setShowMessage(true);
//     }, 2000); // 2000 milliseconds = 2 seconds

//     return () => clearTimeout(timer); // Cleanup the timer
// }, []); // Empty dependency array ensures this effect runs only once after the initial render
            // {/* {showMessage && (
            //     <>
            //         <br/>
            //         <br/>
            //         로딩 시간이 길어서 이 문구가 보이신다면 서버가 잠든 상태입니다.
            //         <br/>
            //         일정 시간 사이트 이용이 없으면 서버가 잠듭니다.
            //         <br/>
            //         서버가 깨어나려면 30초에서 1분의 시간이 걸립니다.
            //         <br/>
            //         바로 접속이 안되면 잠시만 기다려주세요.
            //         <br/>
            //         약 1만원(한 달)의 서버 비용을 줄이기 위해서 위와 같은 불편을 드리게 되었습니다.
            //         <br/>
            //         <br/>
            //         불편을 드려 죄송합니다.
            //     </>
            // )} */}

// const Loading = () => {
//     return <div style={{ marginTop: 30, textAlign: "center", color: "#7c73e6" }}>
//         잠시만 기다려주세요
//         <br/>
//         <br/>
//         로딩 시간이 길어서 이 문구가 보이신다면 서버가 잠든 상태입니다.
//         <br/>
//         일정 시간 사이트 이용이 없으면 서버가 잠듭니다.
//         <br/>
//         서버가 깨어나려면 30초에서 1분의 시간이 걸립니다.
//         <br/>
//         바로 접속이 안되면 잠시만 기다려주세요.
//         <br/>
//         서버 비용 절감을 위해서 위와 같은 불편을 드리게 되었습니다.
//         <br/>
//         <br/>
//         죄송합니다.
//     </div>
// }

// export default Loading