import { useState, SyntheticEvent, useRef, SetStateAction } from 'react'
import { useMutation } from '@apollo/client'
import { ALL_APPS, CREATE_APP } from '../queries'
import { Editor } from './Editor/Editor';
import { $getRoot, LexicalEditor } from 'lexical';
import { CLEAR_EDITOR_COMMAND } from 'lexical';
import { useNavigate } from 'react-router-dom';


const AppForm = ({ setNotification }: { setNotification: (message: string, type: 'success' | 'error') => void }) => {
  const [koreanName, setKoreanName] = useState('')
  const [englishName, setEnglishName] = useState('')
  const [appType, setAppType] = useState('')
  const editorRef = useRef<LexicalEditor | null>();


  const [createApp] = useMutation(CREATE_APP, {
    refetchQueries: [ { query: ALL_APPS } ],
    onError: (error) => {
      setNotification(error.message, 'error');
    },
    update: (cache, response) => {
      cache.updateQuery({ query: ALL_APPS }, ({ allApps }) => {
        return {
          allApps: allApps.concat(response.data.addApp),
        }
      })
    },
  })
  const navigate = useNavigate()
  const submit = async (event: SyntheticEvent) => {
    event.preventDefault()
    
    const latestEditorState = editorRef.current?.getEditorState();
    const textContent = latestEditorState?.read(() =>
      $getRoot().getTextContent()
    );
    const description = textContent;

    const result = await createApp({ variables: { koreanName, englishName, description, appType } })
    if(result.data) {
    setKoreanName('')
    setEnglishName('')
    setNotification(`${koreanName} 추가되었습니다`, 'success')
    navigate('/apps')
    }

    //setDescription('')
    //setApps([])

    if (editorRef.current !== null)
    editorRef.current?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
  }

  // const addApp = () => {
  //   setApps(apps.concat(app))
  //   setApp('');
  // }
  const handleRadioChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setAppType(event.target.value); // Update the selected value state when a radio button is selected
  };


  return (
    <div style={{marginLeft: '1.5%', marginRight: '1.5%'}}>
      <h2>추가하기</h2>
      <form onSubmit={submit}>
        <div>
          이름 <input value={koreanName}
            onChange={({ target }) => setKoreanName(target.value)}
          />
        </div>
        <div>
          영문 이름 <input value={englishName}
            onChange={({ target }) => setEnglishName(target.value)}
          />
        </div>
        <div  className="editorWrapper">
          {/* <input value={description}
            onChange={({ target }) => setDescription(target.value)}
          /> */}
          <Editor ref={editorRef}/>
        </div>
        {/* <div>어플리케이션: {apps.join(' ')}</div> */}
        {/* <div>
          앱 <input value={app}
            onChange={({ target }) => setApp(target.value)}
          />
          <button onClick={addApp} type="button">
            앱 추가
          </button>
        </div> */}
    
      <input type="radio" id="appSortType1" name="appSortType" value="SOCIALMEDIA" onChange={handleRadioChange}/>
      <label htmlFor="appSortType1">소셜미디어(SNS)</label>
      <input type="radio" id="appSortType2" name="appSortType" value="COMMUNITY" onChange={handleRadioChange}/>
      <label htmlFor="appSortType2">커뮤니티</label>
      <input type="radio" id="appSortType3" name="appSortType" value="NEWS" onChange={handleRadioChange}/>
      <label htmlFor="appSortType3">뉴스</label>
      <input type="radio" id="appSortType4" name="appSortType" value="OTT" onChange={handleRadioChange}/>
      <label htmlFor="appSortType4">OTT</label>
      <input type="radio" id="appSortType5" name="appSortType" value="VIDEO" onChange={handleRadioChange}/>
      <label htmlFor="appSortType5">비디오</label>
      <input type="radio" id="appSortType6" name="appSortType" value="GAME" onChange={handleRadioChange}/>
      <label htmlFor="appSortType6">게임</label>
      <hr/>
      <button type='submit' id='submit-button'>추가!</button>
      </form>
    </div>
  )
}

export default AppForm;