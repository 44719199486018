import { useQuery } from "@apollo/client";
import { ALL_BOOKREVIEWS } from "../queries";
import { Link, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import { useEffect, useState } from "react";
import NewNotify from "./NewNotify";


const BookReviewList = ({ token, setNotification }: { token: string | null, setNotification: (message: string, type: 'success' | 'error') => void }) => {
    const [message, setMessage] = useState<string | null>(null)
    const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);
    const location = useLocation();

    useEffect(() => {
        document.title = "서평"
        if (location.pathname === "/bookreviews") {
            setMessage(
                `2024년 10월 23일을 시작으로 서평 꾸준히 업데이트할 예정입니다`
            )
            setMessageType("success");
            setTimeout(() => {
                setMessage(null)
                setMessageType(null)
            }, 4000)
        }
    }, [document.title]);

    const bookReviewResult = useQuery(ALL_BOOKREVIEWS)

    const padding = {
        padding: 5,
        textDecoration: "none",
        color: "white"
    }
    const maxLength = 145;

    return (<div style={{ margin: 10 }}>
        <NewNotify message={message} messageType={messageType} />
         <div>
            <br/>
        </div>
        {bookReviewResult.data?.allBookReviews.map((review: any) => {
            return <div key={review.id} style={{   display: 'inline-block', 
                borderStyle: "inset", 
                marginBottom: 20, 
                padding: 10, 
                maxWidth: window.innerWidth > 700 ? '90%' : '90%', // Responsive width
                marginRight: window.innerWidth > 700 ? '2%' : '2%' }}>
<div style={{marginLeft: 5}}>
                <h4>{review.reviewTitle}</h4>
                <h5>{`『${review.bookTitle}』 | ${review.author}`}</h5>

                <div style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word', overflowWrap: 'break-word',maxWidth: '35ch' }}>
                {review.description.length > maxLength ? review.description.substring(0, maxLength) + "..."  : review.description }
                </div>
                <br/>
                <div>
                    <a href={'/bookreviews/' + review.id}>
                <img src={`${review.imageUrl}`} alt={`${review.bookTitle}`} width="150" height="250" />
                </a>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto' }}>
{<Link to={`/bookreviews/${review.id}`} style={padding}>
    
    <Button variant="contained" >자세히</Button>
        {/* <RefStyledBox>{ref.author} {ref.title}</RefStyledBox> */}
    </Link>}
    </div>
                </div>

            </div>
        })

        }</div>)
}




export default BookReviewList;