import { SyntheticEvent, useState } from "react";
import { DISABLE_USER } from "../queries";
import { useMutation } from "@apollo/client";

const DisableUser = ({setNotification}: { setNotification: (message: string, type: 'success' | 'error') => void}) => {
    const [username, setUsername] = useState('')
    const [disableUser] = useMutation(DISABLE_USER, {
      onError: (error) => {
        setNotification(error.message, 'error');
      }
    })
  
    const submitDisableUser = async (event: SyntheticEvent) => {
      event.preventDefault(); 
     const result = await disableUser({ variables: { username: username } })

      if (result.data) {
      setNotification('삭제되었습니다.', 'success')
      }
    }
  
    return (
        <form onSubmit={submitDisableUser}>
        <div>
          이름 
          <input 
            value={username}
            onChange={({ target }) => setUsername(target.value)}
            placeholder="Enter username to disable"
          />
        </div>
        <button type="submit">삭제</button>
      </form>
    )
}

export default DisableUser