import { useRef, useEffect, SyntheticEvent, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Editor } from './Editor/Editor';

import { ADD_PRIN_REF, EDIT_PRINCIPLE, EDIT_PRINCIPLE_COUNTER, EDIT_PRINCIPLE_ENGLISHNAME, EDIT_PRINCIPLE_KOREANNAME, EDIT_PRIN_REF } from '../queries'
import { $getRoot, LexicalEditor } from 'lexical';
import { PrincipleType } from '../types';

const EditPrinciple = ({setNotification, principle, setOpenEditor}: 
    { setNotification: (message: string, type: 'success' | 'error') => void, principle: PrincipleType, setOpenEditor: React.Dispatch<React.SetStateAction<boolean>>}) => {
  //const [koreanName, setKoreanName] = useState('')
  //const [phone, setPhone] = useState('')

  const [countermeasure, setCountermeasure] = useState('');
  const [koreanName, setKoreanName] = useState('');
  const [englishName, setEnglishName] = useState('');

  const [author, setAuthor] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [linkTitle, setLinkTitle] = useState('');
  const [prinRefId, setPrinRefId] = useState('');

  

  const [ editPrinciple, result ] = useMutation(EDIT_PRINCIPLE, {
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })

  const [editPrincipleCounter] = useMutation(EDIT_PRINCIPLE_COUNTER, {
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })

  const [editPrincipleKoreanName] = useMutation(EDIT_PRINCIPLE_KOREANNAME, {
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })

  const [editPrincipleEnglishName] = useMutation(EDIT_PRINCIPLE_ENGLISHNAME, {
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })

  const [addPrinRef] = useMutation(ADD_PRIN_REF, {
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })

  const [editPrinRef] = useMutation(EDIT_PRIN_REF, {
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })

  const editorRef = useRef<LexicalEditor | null>(null);

  const submit = (event: SyntheticEvent) => {
    event.preventDefault()
    const latestEditorState = editorRef.current?.getEditorState();
    const textContent = latestEditorState?.read(() =>
      $getRoot().getTextContent()
    );
    const description = textContent;

    editPrinciple({ variables: { id:principle.id, description:description } })
    if(!result.error) {
        setOpenEditor(false);
    }
    setNotification('추가되었습니다.', 'success')
  }

  const submitCountermeasure = (event: SyntheticEvent) => {
    event.preventDefault()

    editPrincipleCounter({ variables: { id: principle.id, countermeasure: countermeasure } })

    setNotification('수정되었습니다.', 'success');
  }

  const submitKoreanName = (event: SyntheticEvent) => {
    event.preventDefault()

    editPrincipleKoreanName({ variables: { id: principle.id, koreanName: koreanName } })

    setNotification('한국어 이름이 수정되었습니다.', 'success');
  }

  const submitEnglishName = (event: SyntheticEvent) => {
    event.preventDefault()

    editPrincipleEnglishName({ variables: { id: principle.id, englishName: englishName } })

    setNotification('수정되었습니다.', 'success');
  }

  const submitPrinRef = async (event: SyntheticEvent) => {
    event.preventDefault()

    const result = await addPrinRef
      ({
        variables: {
          author: author, title: title, description: description,
          link: link, linkTitle: linkTitle, principleId: principle.id
        }
      })

    if (result.data) setNotification('추가되었습니다.', 'success');
  }

  const setEditPrinRef = async (ref: any) => {
    // event.preventDefault()
    setAuthor(ref.author);
    setTitle(ref.title);
    setDescription(ref.description);
    setLink(ref.link);
    setLinkTitle(ref.linkTitle);
    setPrinRefId(ref.id)
  }

  const submitEditPrinRef = async (event: SyntheticEvent) => {
    event.preventDefault()

    const result = await editPrinRef
      ({
        variables: {
          author: author, title: title, description: description,
          link: link, linkTitle: linkTitle, id: prinRefId
        }
      })

    if (result.data) setNotification('수정되었습니다.', 'success');
  }


  useEffect(() => {
    if (result.data && result.data.editDescription === null) {
      setNotification('person not found', 'error')
    }
  }, [result.data]) // eslint-disable-line 

  return (
    <div>
      <h2>{principle.names.koreanName} 설명 추가하기</h2>

      <form onSubmit={submit}>
        <div>
          {/* <input
            value={koreanName}
            onChange={({ target }) => setKoreanName(target.value)}
          /> */}
        </div>
        <div className="editorWrapper">
          {/* <input
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
          /> */}
        <Editor ref={editorRef} description={principle.description}/>
        </div>
        <button type='submit'>수정</button>
      </form>
      <button type='button' onClick={() => setOpenEditor(false)}>취소</button>
      <hr/>
      <form onSubmit={submitKoreanName}>
        <input type="text" onChange={c => setKoreanName(c.currentTarget.value)}></input>
        <button type='submit'>
          한국어 이름 수정</button>
      </form>
      <form onSubmit={submitEnglishName}>
        <input type="text" onChange={c => setEnglishName(c.currentTarget.value)}></input>
        <button type='submit'>
          영문 이름 수정</button>
      </form>
      <form onSubmit={submitCountermeasure}>
        <input type="text" onChange={c => setCountermeasure(c.currentTarget.value)}></input>
        <button type='submit'>
          대처법 수정</button>
      </form>
      <hr/>
      <form onSubmit={submitPrinRef}>
        <div>author<input type="text" onChange={c => setAuthor(c.currentTarget.value)}></input></div>
        <div>title<input type="text" onChange={c => setTitle(c.currentTarget.value)}></input></div>
        <div>description<input type="text" onChange={c => setDescription(c.currentTarget.value)}></input></div>
        <div>link<input type="text" onChange={c => setLink(c.currentTarget.value)}></input></div>
        <div>linkTitle<input type="text" onChange={c => setLinkTitle(c.currentTarget.value)}></input></div>
        <button type='submit'>
          참고자료 올리기</button>
      </form>
      {principle.prin_refs.map((ref) => {
        return <div key={ref.id}><button onClick={() => setEditPrinRef(ref)}>{ref.title + " | " + ref.author + " | " + ref.description
         + " | " + ref.link + " | " + ref.linkTitle}</button></div>
      })}
      <hr/>
      <form onSubmit={submitEditPrinRef}>
        <div>author<input 
        value={author}
        type="text" onChange={c => setAuthor(c.currentTarget.value)}></input></div>
        <div>title<input 
        value={title}
        type="text" onChange={c => setTitle(c.currentTarget.value)}></input></div>
        <div>description<input 
        value={description}
        type="text" onChange={c => setDescription(c.currentTarget.value)}></input></div>
        <div>link<input 
        value={link}
        type="text" onChange={c => setLink(c.currentTarget.value)}></input></div>
        <div>linkTitle<input 
        value={linkTitle}
        type="text" onChange={c => setLinkTitle(c.currentTarget.value)}></input></div>
        <div>appRefId<input 
        value={prinRefId}
        type="text" onChange={c => setPrinRefId(c.currentTarget.value)}></input></div>
        <button type='submit'>
          참고자료 수정하기</button>
      </form>
      
    </div>
  )
}

export default EditPrinciple;