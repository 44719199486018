import { useRef, useEffect, SyntheticEvent, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Editor } from './Editor/Editor';

import { $getRoot, LexicalEditor } from 'lexical';
import { RefType } from '../types';
import { ALL_REFS, DELETE_REF, EDIT_REF } from '../queries';
import { useNavigate } from 'react-router-dom';

const EditRef = ({ setNotification, reference, setOpenEditor }:
  { setNotification: (message: string, type: 'success' | 'error') => void, reference: RefType, setOpenEditor: React.Dispatch<React.SetStateAction<boolean>> }) => {

  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [linkTitle, setLinkTitle] = useState('');

  const navigate = useNavigate()

  const [editRef, result] = useMutation(EDIT_REF, {
    onError: (error) => {
      //const messages = error.graphQLErrors[0].message
      setNotification(error.message, 'error');
    },
  })
  const [deleteRef] = useMutation(DELETE_REF, {
    refetchQueries: [{ query: ALL_REFS }],
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })
  const editorRef = useRef<LexicalEditor | null>(null);

  const submit = (event: SyntheticEvent) => {
    event.preventDefault()
    const latestEditorState = editorRef.current?.getEditorState();
    const textContent = latestEditorState?.read(() =>
      $getRoot().getTextContent()
    );
    const description = textContent;
    editRef({
      variables: {
        id: reference.id, author: author, title: title,
        description: description, link: link, linkTitle: linkTitle
      }
    })

    if (!result.error) {
      setOpenEditor(false);
    }
    setNotification('추가되었습니다.', 'success');
  }

  const submitDeleteRef = async (id: number) => {
    const result = await deleteRef
      ({
        variables: {
          id: id
        }
      })

    if (result.data) setNotification('삭제되었습니다.', 'success');

    navigate('/references')
  }

  useEffect(() => {
    if (result.data && result.data.editDescription === null) {
      setNotification('Not Found', 'error')
    }
    setTitle(reference.title.toString())
    setAuthor(reference.author.toString())
    setLink(reference.link.toString())
    setLinkTitle(reference.linkTitle.toString())
  }, [result.data]) // eslint-disable-line 

  //   useEffect(() => {
  //     // Fetch the existing value from your data source (e.g., an API call or from props)
  //     const existingValue = app.countermeasure; // Replace with your logic to fetch the value

  //     // Set the existing value to the state if it exists
  //     if (existingValue) {
  //       setCountermeasure(existingValue);
  //     }
  //   }, [app.countermeasure]);
  return (
    <div>
      <h2>{reference.title} 참고자료 수정하기</h2>

      <form onSubmit={submit}>
        <div>
          제목 <input
            value={title}
            onChange={({ target }) => setTitle(target.value)}
          />
        </div>        <div>
          저자 <input
            value={author}
            onChange={({ target }) => setAuthor(target.value)}
          />
        </div>
        <div className="editorWrapper">
          {/* <input
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
          /> */}
          <Editor ref={editorRef} description={reference.description} />
        </div>
        <div>
          링크 주소 <input
            value={link}
            onChange={({ target }) => setLink(target.value)}
          />
        </div>
        <div>
          링크 제목<input
            value={linkTitle}
            onChange={({ target }) => setLinkTitle(target.value)}
          />
        </div>
        <button type='submit'>수정</button>
      </form>
      <button type='button' onClick={() => setOpenEditor(false)}>취소</button>
      <hr />
      <button onClick={() => submitDeleteRef(reference.id)}>{"삭제"}</button>
      <hr />
    </div>
  )
}

export default EditRef;