import * as React from 'react';
import '../styles.css';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { ToolbarPlugin } from './toolbar/Toolbar';
import { BannerNode } from './plugins/banner/BannerPlugin';
import { $createTextNode } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, $getSelection } from 'lexical';
import { $createParagraphNode } from 'lexical';

const theme = {
    heading: {
        h1: 'trap-editor-h1',
        h2: 'trap-editor-h2',
        h3: 'trap-editor-h3'
    },
    text: {
        bold: 'trap-editor-bold',
        italic: 'trap-editor-italic'
    },
    banner: 'trap-editor-banner'
};


function onError(error: Error): void {
    console.error(error);
}

const EditorCapturePlugin = React.forwardRef((props: any, ref: any) => {
    const [editor] = useLexicalComposerContext();

    React.useEffect(() => {
        if (editor) {
        editor.update(() => {
            // Get the RootNode from the EditorState
            const root = $getRoot();
    
            // Get the selection from the EditorState
            const selection = $getSelection();
    
            // Create a new ParagraphNode
            const paragraphNode = $createParagraphNode();
    
            // Create a new TextNode
            const textNode = $createTextNode(props.description);
    
            // Append the text node to the paragraph
            paragraphNode.append(textNode);
    
            // Finally, append the paragraph to the root
            root.clear();
            root.append(paragraphNode); 

        });
    }
        ref.current = editor;
    }, [editor, ref, props.description]);

    return null;
})

export const Editor = React.forwardRef((props: any, ref: any) => {
    const initialConfig = {
        namespace: 'MyEditor',
        theme,
        onError,
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            BannerNode
        ],
    };

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <ToolbarPlugin />
            {/* <ListPlugin/>
                <BannerPlugin/> */}
            <RichTextPlugin
                contentEditable={<ContentEditable className="contentEditable" />}
                placeholder={<div className="placeholder">설명을 적어주세요...</div>}
                ErrorBoundary={LexicalErrorBoundary}
            />
            <EditorCapturePlugin ref={ref} description={props.description} />
            {/* <OnChangePlugin onChange={onChange} /> */}
            <HistoryPlugin />
        </LexicalComposer>
    );
});