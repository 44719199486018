import { AppType, PrincipleType } from "../types";

const ForXml = ({ apps, principles }: { apps: AppType[], principles: PrincipleType[] }) => {
    return (<div>
        {apps.map((a) => `'${a.names.koreanName}', `)}
        <hr/>
        {principles.map((p) => `'${p.names.koreanName}',`)}
    </div>)
}

export default ForXml;