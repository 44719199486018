
import styled from 'styled-components';

export const Button = styled.button`
    background: #e7e6f7;
    font-size: 1em;
    margin: 0.5em;
    padding: 0.125em 0.5em;
    border: 2px solid #ffe0e9;
    border-radius: 3px;
`

export const Input = styled.input`
    margin: 0.25em;
`

export const Page = styled.div`
    padding: 1em;
    background: #edf2fb;
    border-radius: 10px;
`

// margin-left: auto;
// margin-right: auto;


// display: flex;
// background: #d7e3fc;
// padding: 1em;
// width: 100%;
// height: 100%;
// border-radius: 10px;
export const NavigationDiv = styled.nav`
display: flex;
flex-direction: row;
align-items: center;
justify-content: start;
background-color: #66bfbf;
background: linear-gradient(to left, #66bfbf #bbe4e9);
/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
color: #FFF;
height: 30px;
width: 100%
margin: 0;
padding: 1em;
position: relative;
top: 0%;
left: 0%;
right: 0%;
z-index: 1;
overflow-x: scroll;
overflow-y: hidden;
white-space: nowrap;
`

export const BelowNavigationDiv = styled.nav`
display: flex;
flex-direction: row;
align-items: center;
justify-content: start;
background-color: #66bfbf;
background: linear-gradient(to left, #66bfbf #bbe4e9);
/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
color: #FFF;
height: 30px;
width: 100%
margin: 0;
padding: 1em;
position: relative;
top: 30px;
left: 0%;
right: 0%;
z-index: 1;
overflow-x: scroll;
white-space: nowrap;
`

export const Footer = styled.div`
    background: #faf9f9;
    padding: 1em;
    margin-top: 1em;
    border-radius: 10px;
`

export const InlineDiv = styled.div`
    display: flex;
`
//    alignItem: center;

// export const EditorTheme = styled.div`
// .ltr {
//   text-align: left;
// }

// .rtl {
//   text-align: right;
// }

// .editor-placeholder {
//   color: #999;
//   overflow: hidden;
//   position: absolute;
//   top: 15px;
//   left: 15px;
//   user-select: none;
//   pointer-events: none;
// }

// .editor-paragraph {
//   margin: 0 0 15px 0;
//   position: relative;
// }
// `;

export const EditorTheme = {
  ltr: 'ltr',
  rtl: 'rtl',
  paragraph: 'editor-paragraph',
  quote: 'editor-quote',
  heading: {
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
    h4: 'editor-heading-h4',
    h5: 'editor-heading-h5',
    h6: 'editor-heading-h6',
  },
  list: {
    nested: {
      listitem: 'editor-nested-listitem',
    },
    ol: 'editor-list-ol',
    ul: 'editor-list-ul',
    listitem: 'editor-listItem',
    listitemChecked: 'editor-listItemChecked',
    listitemUnchecked: 'editor-listItemUnchecked',
  },
  hashtag: 'editor-hashtag',
  image: 'editor-image',
  link: 'editor-link',
  text: {
    bold: 'editor-textBold',
    code: 'editor-textCode',
    italic: 'editor-textItalic',
    strikethrough: 'editor-textStrikethrough',
    subscript: 'editor-textSubscript',
    superscript: 'editor-textSuperscript',
    underline: 'editor-textUnderline',
    underlineStrikethrough: 'editor-textUnderlineStrikethrough',
  },
  code: 'editor-code',
  codeHighlight: {
    atrule: 'editor-tokenAttr',
    attr: 'editor-tokenAttr',
    boolean: 'editor-tokenProperty',
    builtin: 'editor-tokenSelector',
    cdata: 'editor-tokenComment',
    char: 'editor-tokenSelector',
    class: 'editor-tokenFunction',
    'class-name': 'editor-tokenFunction',
    comment: 'editor-tokenComment',
    constant: 'editor-tokenProperty',
    deleted: 'editor-tokenProperty',
    doctype: 'editor-tokenComment',
    entity: 'editor-tokenOperator',
    function: 'editor-tokenFunction',
    important: 'editor-tokenVariable',
    inserted: 'editor-tokenSelector',
    keyword: 'editor-tokenAttr',
    namespace: 'editor-tokenVariable',
    number: 'editor-tokenProperty',
    operator: 'editor-tokenOperator',
    prolog: 'editor-tokenComment',
    property: 'editor-tokenProperty',
    punctuation: 'editor-tokenPunctuation',
    regex: 'editor-tokenVariable',
    selector: 'editor-tokenSelector',
    string: 'editor-tokenSelector',
    symbol: 'editor-tokenProperty',
    tag: 'editor-tokenProperty',
    url: 'editor-tokenOperator',
    variable: 'editor-tokenVariable',
  },
};

export const EditorTheme2 = styled.div`
  .ltr {
    text-align: left;
  }

  .rtl {
    text-align: right;
  }

  .editor-placeholder {
    color: #999;
    overflow: hidden;
    position: absolute;
    top: 15px;
    left: 15px;
    user-select: none;
    pointer-events: none;
  }

  .editor-paragraph {
    margin: 0 0 15px 0;
    position: relative;
  }
`;

export const EditorTheme3 = styled.div`
root: 'p-4 border-slate-500 border-2 rounded h-full min-h-[200px] focus:outline-none focus-visible:border-black',
link: 'cursor-pointer',
text: {
  bold: 'font-semibold',
  underline: 'underline',
  italic: 'italic',
  strikethrough: 'line-through',
  underlineStrikethrough: 'underlined-line-through'
  `

export const EditorTheme4 = styled.div`
  background: #edf2fb;
`

export const StyledDiv = styled.div`
  margin: 0%;
  ::selection {
    background: #ffe700; /* Set the background color of the selection */
  }
`;