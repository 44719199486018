const Time = ({createdAt, updatedAt}: { createdAt: String, updatedAt: String}) => {
    return (                
    <div>
    <br/>
    최초 게시: {new Date(Number(createdAt)).toLocaleString('ko-KR')}
    <br/>
    최근 수정: {new Date(Number(updatedAt)).toLocaleString('ko-KR')}
    </div>)
}

export default Time