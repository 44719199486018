import { useState, SyntheticEvent, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { ADD_BOOKREVIEW, ALL_BOOKREVIEWS } from '../queries'
import { Editor } from './Editor/Editor';
import { $getRoot, LexicalEditor } from 'lexical';
import { CLEAR_EDITOR_COMMAND } from 'lexical';
import { useNavigate } from 'react-router-dom';


const BookReviewForm = ({ setNotification }: { setNotification: (message: string, type: 'success' | 'error') => void }) => {
  const [author, setAuthor] = useState('')
  const [bookTitle, setBookTitle] = useState('')
  const [reviewTitle, setReviewTitle] = useState('')
  const [description, setDescription] = useState('')
  const [imageUrl, setImageUrl] = useState('')

  const editorRef = useRef<LexicalEditor | null>();

  const [createBookReview] = useMutation(ADD_BOOKREVIEW, {
    update: (cache, response) => {
      cache.updateQuery({ query: ALL_BOOKREVIEWS }, ({ allBookReviews }) => {
        return {
          allBookReviews: allBookReviews?.concat(response.data.addBookReview),
        }
      })
    },
  })
  const navigate = useNavigate()
  const submit = async (event: SyntheticEvent) => {
    event.preventDefault()
    
    const latestEditorState = editorRef.current?.getEditorState();
    const textContent = latestEditorState?.read(() =>
      $getRoot().getTextContent()
    );
    const description = textContent;

    const result = await createBookReview({ variables: { author, bookTitle, reviewTitle, description, imageUrl } })
    if(result.data) {
    setAuthor('')
    setBookTitle('')
    setReviewTitle('')
    setDescription('')
    setImageUrl('')
    setNotification(`${bookTitle} 추가되었습니다`, 'success')
    navigate('/bookreviews')
    }

    if (editorRef.current !== null)
    editorRef.current?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
  }

  return (
    <div style={{marginLeft: '1.5%', marginRight: '1.5%'}}>
      <h2>추가하기</h2>
      <form onSubmit={submit}>
        <div>
          저자 <input value={author}
            onChange={({ target }) => setAuthor(target.value)}
          />
        </div>
        <div>
          책 제목 <input value={bookTitle}
            onChange={({ target }) => setBookTitle(target.value)}
          />
        </div>
        <div>
          리뷰 제목 <input value={reviewTitle}
            onChange={({ target }) => setReviewTitle(target.value)}
          />
        </div>
        <div  className="editorWrapper">
          {/* <input value={description}
            onChange={({ target }) => setDescription(target.value)}
          /> */}
          <Editor ref={editorRef} />
        </div>
        {/* <div>어플리케이션: {apps.join(' ')}</div> */}
        {/* <div>
          앱 <input value={app}
            onChange={({ target }) => setApp(target.value)}
          />
          <button onClick={addApp} type="button">
            앱 추가
          </button>
        </div> */}
                <div>
          이미지 주소 <input value={imageUrl}
            onChange={({ target }) => setImageUrl(target.value)}
          />
        </div>
      <hr/>
      <button type='submit' id='submit-button'>추가!</button>
      </form>
    </div>
  )
}

export default BookReviewForm;