import {$getSelection, $isRangeSelection} from 'lexical';

import * as React from 'react';

import { $createHeadingNode } from '@lexical/rich-text';

import { $setBlocksType } from '@lexical/selection';
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_BANNER_COMMAND } from '../plugins/banner/BannerPlugin';

type HeadingTag = 'h1' | 'h2' | 'h3'
function HeadingToolbarPlugin(): JSX.Element {
    const [editor] = useLexicalComposerContext();
    const headingTags: HeadingTag[] = ['h1', 'h2', 'h3'];
    const onClick = (tag: HeadingTag): void => {
        editor.update(() => {
            const selection = $getSelection();
            if($isRangeSelection(selection)) {
                $setBlocksType(selection, () => $createHeadingNode(tag))
            }
        })
    }
    return <>{headingTags.map((tag) => (
        <button type='button' onClick={() => { onClick(tag) }} key={tag}>{tag.toUpperCase()}</button>
    ))}</>
}

type ListTag = 'ol' | 'ul'
function ListToolbarPlugin(): JSX.Element {
    const [editor] = useLexicalComposerContext();
    const listTags: ListTag[] = ['ol', 'ul'];
    const onClick = (tag: ListTag): void => {
        if(tag === 'ol') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
            return;
        }
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    }
    return <>{listTags.map((tag) => (
        <button type='button' onClick={() => { onClick(tag) }} key={tag}>{tag.toUpperCase()}</button>
    ))}</>
}

export function BannerToolbarPlugin(): JSX.Element {
    const [editor] = useLexicalComposerContext();
    const onClick = (e: React.MouseEvent): void => {
        editor.dispatchCommand(INSERT_BANNER_COMMAND, undefined);
    }
    return <button type='button' onClick={onClick}>Banner</button>;
}


export function ToolbarPlugin(): JSX.Element {
    return <div className="toolbar-wrapper">
        <HeadingToolbarPlugin/>
        <ListToolbarPlugin/>
        <BannerToolbarPlugin/>
    </div>
}


// function MyOnChangePlugin(props: { onChange: (editorState: EditorState) => void}): null {
//     const [editor] = useLexicalComposerContext();
//     const { onChange } = props;
//     React.useEffect(() => {
//         return editor.registerUpdateListener(({editorState}) => {
//             onChange(editorState);
//         })
//     }, [onChange, editor])
//     return null;
// }