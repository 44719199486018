import { useEffect } from "react";
import { Link } from "react-router-dom";

const Donation = () => {
    useEffect(() => {
        document.title = '서버 비용 후원 안내'
    }, []);
    return (
        <div style={{ marginTop: 20, marginLeft: '5%', marginRight: '10%' }}>
            <h3>서버 비용 후원 안내</h3>
            <div>트랩위키는 디디털 기기와 어플리케이션을 통제력을 갖고 사용하는 법을 연구합니다. </div>
            <br />
            <div>매달 2만원의 서버 비용이 발생하여 아래 후원 사이트에서 후원 받게 되었습니다.</div>
            <br />
            <div>TrapWiki studies how to use digital devices and applications with control.</div>
            <br />
            <div>We incur a monthly server cost of 20,000 KRW and have started accepting donations through the following donation site to cover these expenses.</div>
            <br />
            <div>
            <Link to='https://buymeacoffee.com/trapwiki' style={{ color: "#7c73e6", textDecoration: "none" }}>
            https://buymeacoffee.com/trapwiki (외부 링크)</Link>,

            </div>
           
            <br />

        </div>
    )
}

export default Donation;