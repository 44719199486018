import { useState, SyntheticEvent } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { ADD_APP_PRINCIPLE_COMMENT, ALL_PRINCIPLES, CONNECT_PRINCIPLE_TO_APP, FIND_APP, FIND_PRINCIPLE } from '../queries'


const ConnectAppToPrinciple = ({ setNotification, appId }:
    { setNotification: (message: string, type: 'success' | 'error') => void, appId: number }) => {
    const principlesResult = useQuery(ALL_PRINCIPLES);
    const [principleButtonId, setPrincipleButtonId] = useState<number | null>(null);
    const [comment, setComment] = useState('');
    // const result = useQuery(FIND_PRINCIPLE, {
    //     variables: { idToSearch },
    //     skip: !idToSearch 
    // })
    const [connectPrincipleToApp] = useMutation(CONNECT_PRINCIPLE_TO_APP, {
        refetchQueries: [{ query: FIND_APP, variables: { idToSearch: appId } }],
        onError: (error) => {
            setNotification(error.message, 'error');
        }
    })

    const [addComment] = useMutation(ADD_APP_PRINCIPLE_COMMENT, {
        refetchQueries: [
            { query: FIND_APP, variables: { idToSearch: appId } },
            { query: FIND_PRINCIPLE, variables: { idToSearch: principleButtonId } }
        ],
        onError: (error) => {
            setNotification(error.message, 'error');
        }
    })

    const submitConnect = async (event: SyntheticEvent) => {
        event.preventDefault()
        const result = await connectPrincipleToApp({ variables: { appId: appId, principleId: principleButtonId } })
        if (result.data) setNotification('추가되었습니다.', 'success');
    }

    const submitComment = async (event: SyntheticEvent) => {
        event.preventDefault()
        const result = await addComment({ variables: { appId: appId, principleId: principleButtonId, comment: comment } })
        if (result.data) setNotification('설명이 추가되었습니다.', 'success');
    }

    return (
        <div>
            <h2>추가하기</h2>
            <form onSubmit={submitConnect}>
                {principlesResult?.data?.allPrinciples?.map((p: any) =>
                    <button key={p.id} type='submit' onClick={() => setPrincipleButtonId(p.id)}>
                        {p.names.koreanName}</button>)}
                {/* <button type='submit' id='submit-button'>추가!</button> */}
            </form>
            <h2>구체적 사용 장소 설명하기</h2>

            <form onSubmit={submitComment}>
                {principlesResult?.data?.allPrinciples?.map((p: any) =>
                    <div key={p.id}>
                        <input type="text" onChange={c => setComment(c.currentTarget.value)}></input>
                        <button type='submit' onClick={() => setPrincipleButtonId(p.id)}>
                            {p.names.koreanName}</button>
                    </div>)}
            </form>

            {/* <button type='submit' id='submit-button'>추가!</button> */}

        </div>
    )
}

export default ConnectAppToPrinciple;