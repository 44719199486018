//import { Link } from "react-router-dom";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import { SuggestionBox } from "./styled_components/Boxs";
import { useQuery } from "@apollo/client";
import { PAGE_STRING } from "../queries";

const Suggestion = () => {
    const [inputPassword, setInputPassword] = useState('');
    const [isAuthorized, setIsAuthorized] = useState(false);

    const { data, loading, error } = useQuery(PAGE_STRING);

    const handlePasswordCheck = () => {
        if (inputPassword === data.pageString) {
          setIsAuthorized(true);
        } else {
          alert('');
        }
      };

    useEffect(() => {
        document.title = "이용자 의견"
    }, []);
    const [message, setMessage] = useState<string | null>(null)

    const notify = (message: string) => {
        setMessage(message)
        setTimeout(() => {
            setMessage(null)
        }, 5000)
    }
    return (
        <div style={{ marginRight: '1.5%' }}>
            <ul>
                <li>
                    <SuggestionBox>
                    <a style={{ color: "inherit" }}
                        href="https://naver.me/F9z7aXsJ">
                        <div>트랩위키 이용자 의견</div>
                        (네이버 폼 외부 링크)
                    </a>
                    </SuggestionBox>
                </li>
                <hr />
                {/* <li id="text">
                    이메일: trapwiki123@gmail.com
                </li> */}
                <li>
                    <SuggestionBox>
                        <CopyToClipboard text="trapwiki123@gmail.com" onCopy={() => notify("클립보드에 복사되었습니다.")}>
                            <text>trapwiki123@gmail.com
                            <br/>
                            (이메일 복사 <ContentCutIcon fontSize="small" />)</text></CopyToClipboard>
                    </SuggestionBox>
                </li>
                <div style={{
                    //textAlign: 'center',
                    color: '#2eb872',
                    padding: '10px', // Optional: Add padding for better appearance
                    animationDelay: '5s',
                    //marginLeft: '20%',
                    //marginRight: '20%'
                }}>{message}</div>
                <hr/>
            </ul>
{/* 
            {!isAuthorized ? (
        <div>
          <input
            type="password"
            value={inputPassword}
            onChange={(e) => setInputPassword(e.target.value)}
          />
          <button onClick={handlePasswordCheck}></button>
        </div>
      ) : (
        <div>
                <li>
                    <Link style={{
                        padding: 5,
                        textDecoration: "none",
                        color: "inherit",
                        fontSize: 10
                    }} to='/login'>관리자용 로그인 페이지</Link>
    
    <Link style={{
                        padding: 5,
                        textDecoration: "none",
                        color: "inherit",
                        fontSize: 10
                    }} to='/signup'>관리자용 회원가입 페이지</Link>
                               <Link style={{
                        padding: 5,
                        textDecoration: "none",
                        color: "inherit",
                        fontSize: 10
                    }} to='/disable_user'>유저 삭제</Link>
                    </li>
        </div>
      )} */}
        </div>
    )
}

export default Suggestion;