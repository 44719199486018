
const Notify = ({ message, messageType }: { message: string | null, messageType: 'success' | 'error' | null},) => {
    if (!message) {
        return null
    }
    
    return (
        <div style={
            {position: 'fixed',
            // top: positionStyle ? '15%' : '25%',
            // left: positionStyle ? '50%' : '20%',
            top: 230,
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000}
        }>
            <div style={{
        textAlign: 'center',
        color: messageType === 'success' ? '#2eb872' : 'red',
        padding: '10px', // Optional: Add padding for better appearance
        animationDelay: '5s',
        marginLeft: '20%',
        marginRight: '20%'
    }}>
        {/* {message} */}
                {/* {message.split('\n').map((line, index) => (
                <p key={index} style={{ margin: 0 }}>{line}</p> */}

{message.split('\n').map((line, index) => (
                <p key={index} style={{ margin: '10px 0' }}>{line}</p>
            ))}
    </div>
        </div>
    );

}

export default Notify;