import { useEffect } from "react";

const OS = () => {
  useEffect(() => {
    document.title = '운영체제별 대처법'
}, []);
  return (
    <div style={{ marginLeft: window.innerWidth > 700 ? '1%': '3%', marginBottom: "2%", maxWidth: window.innerWidth > 700 ? '40%' : '100%', // Responsive width
      marginRight: window.innerWidth > 700 ? '10%' : '5%' }}>
      <h2>운영체제별 대처법</h2>
      <hr />
      <h3>안드로이드 Android</h3>
      <div>안드로이드는 앱 개발자에게 '다른 앱 위에 그리기(ViewOverlay)' 기능을 제공한다.
        <br />
        <br />
        개발자들은 이 기능을 사용해서 화면 잠금 어플리케이션을 만들 수 있다.
        <br />
        <br />구글 스토어에 화면 잠금이나 앱 블록 등을 검색하면 찾을 수 있다.
        <br />
        <br />
        다만 해당 앱들이 운영체제에서 지원하는 게 아니어서 기능이 불완전하거나 유료 서비스인 경우가 있다.
        <br />
        해당 앱이 잘 만들어진 경우 애플의 스크린 타임 기능보다 해제하기 힘들다 (잠금 해제하기 위해서는 결제 필요).
      </div>
      <hr />
      <h3>iOS & 맥 OS(MacOs)</h3>
      <div>iOS와 맥 OS(MacOS)는 운영체제 수준에서 <a href="https://support.apple.com/ko-kr/HT208982"
        style={{ color: "#7c73e6", textDecoration: "none" }}>스크린 타임(ios screen time, 링크)</a> 기능을 지원한다.
        <br />
        <br />
        <a href="https://support.apple.com/ko-kr/HT210387"
          style={{ color: "#7c73e6", textDecoration: "none" }}>- 애플 홈페이지 맥 스크린 타임 링크(MasOS screen time)</a>
        <br />
        <br />
        <div>스크린 타임에는 비밀번호 설정이 가능하다. 만약 본인이 직접 비밀번호를 설정하는 경우 쉽게 풀 수 있다.
          스크린 타임의 '다운타임' 기능을 사용하면 자신이 원하는 앱만 허용할 수 있다.
          <br />
          스크린 타임의 비밀번호는 애플 계정이 있으면 쉽게 재설정할 수 있다.
          이러한 특징을 바탕으로 휴대폰 사용을 다음과 같이 제한할 수 있다.
          <br/>
          (안드로이드 휴대폰 잠금 앱의 결제를 통한 '잠금해제' 아이디어를 활용한 방법)
          <br />
          <br />
          1. 스크린 타임의 '다운타임' 기능으로 '사파리', '유튜브' 등의 앱 실행을 막아둠(필요한 앱은 허용해 둠)
          <br />
          2. 스크린 타임의 비밀번호를 기억하지 못하도록 무작위로 설정함 (잠금 해제가 필요하면 애플 계정으로 비밀번호 재설정)
          <br />
          3. 애플 계정의 비밀번호를 쉽게 쓸 수 없는 형식으로 적으면 스크린 타임의 비밀번호를 습관적으로 재설정하는 걸 방지 할 수 있음.
          <br />
          4. 불필요한 이유로 비밀번호를 재설정하고 휴대폰을 사용할 시 스스로에게 벌칙을 줌 (예를 들면 한번 해제하면 일정 금액의 벌금을 기부함)
          <br />
          (<a href="https://www.chosun.com/opinion/specialist_column/2024/03/09/MP62SZO5RVCBRCQRL3L4TGTQ2M/" style={{ color: "#7c73e6", textDecoration: "none" }}>
            백영옥 "싫어하는 마음의 힘"
          </a>
            : 부정적으로 생각하는 기관이나 단체에 벌금을 기부하는 게 더 효과적일 수 있다는 의견)

          
        </div>
        <div>
          <br />
          {/* 비밀번호를 쉽게 푸는 문제를 해결하기 위해 비밀번호를 저장해두는 영어권 사이트가 있다.
          <br />
          <br />
          <a href="https://password-locker.com/" style={{ color: "#7c73e6", textDecoration: "none" }}>
            - 비밀번호 보관함(password locker) 사이트 링크 (일년 $11.99)
          </a>
          <br />
          <br />
          애플이 공식 지원하는 기능이 아니기에 검증이 필요하다. 4자리 비밀번호 저장은 쉽게 기억날 수 있으며, 애플 계정의 비밀번호 저장은 
          애플 계정의 비밀번호가 필요한 경우가 있기에 조금 사용하기 애매하다.
          <br />
          차라리 가족이나 지인에게 스크린 타임 비밀번호 설정을 부탁하는 게 날 것 같다. */}

        맥에서는 스크린 타임-다운 타임 기능이 iOS보다 불완전하게 작동한다. 콜드 터키를 사용하면 이러한 점을 보완할 수 있다.
        <br/>
        <a href="https://getcoldturkey.com/" style={{ color: "#7c73e6", textDecoration: "none" }}>
          콜드 터키(Cold Turkey)
        </a>는 아래 '윈도우' 항목 참고.
        <br/>
        <br/>
        맥 사파리에서 콜드 터키 브라우저 익스텐션(Extension)이 안 되는 경우가 있다.
        <br/>
        <a href="https://getcoldturkey.com/support/extensions/mac/safari/full-disk-access/" style={{ color: "#7c73e6", textDecoration: "none" }}>
         {" "}이 경우, 맥 설정에서 콜드 터키에 관련 권한을 부여해야 한다.
          </a>
        {" "}해당 권한을 사용자 임의로 해제할 수 있기에 다음 대안이 필요하다.
        <br/>
        콜드 터키는 어플리케이션 차단이 가능하다. 콜드 터키 설정 사파리 앱 차단 버튼이 있다(또는 직접 사파리 앱(Safari.app)을 차단)
        <br/>
        맥에서 사파리를 차단하고 콜드 터키가 적용되는 다른 브라우저를 사용할 수 있다.
        <br/>
        {/* <a href="https://nektony.com/blog/how-to-uninstall-safari-on-mac" style={{ color: "#7c73e6", textDecoration: "none" }}>
         {" "}사파리를 삭제
          </a>
        하는 것도 가능하다고 알려져 있다. */}
        <br/>
        맥은 컴퓨터 제조사와 운영체제 제조사가 같아서 개인적으로 콜드 터키를 우회하는 방법을 찾지 못했다.
        </div>
      </div>
      <hr />
      <h3>윈도우 Windows</h3>
      <div>
        <a href="https://getcoldturkey.com/" style={{ color: "#7c73e6", textDecoration: "none" }}>
          콜드 터키(Cold Turkey)
        </a>는 윈도우와 맥에서 이용할 수 있는 앱 차단 프로그램이다 (부분 유료).
        <br />
        <br />
        정말 좋은 앱이지만 윈도우에서 직접 지원하는 게 아니어서 기능상으로 완벽하지 않다.
        <br />
        윈도우 운영체제가 개별 프로그램(콜드 터키)에 주는 권한에 한계가 있어서 그렇다.
        <br />
        윈도우에서는 컴퓨터 제조사와 운영체제 제조사가 다르기에 콜드 터키를 우회하기가 맥보다 쉽다.
        <br />
        <br />
        윈도우는{" "}
        <a href="https://www.microsoft.com/ko-kr/microsoft-365/family-safety?rtc=1" style={{ color: "#7c73e6", textDecoration: "none" }}>
          Microsoft Family Safety
        </a>라는 서비스를 제공한다.
        <br />
        부모가 자녀의 기기 이용 시간을 통제하는 기능은 ios와 안드로이드에도 존재한다.
      </div>
      <hr />
      {/* <table>
  <caption> */}

      {/* </caption>
  <tr>
    <th scope="col">운영체제</th>
    <th scope="col">Gloobles</th>
    <th scope="col">Za'taak</th>
  </tr>
  <tr>
    <th scope="row">안드로이드</th>
    <td>ios</td>
    <td>4,569</td>
  </tr>
  <tr>
    <th scope="row">Khiresh Odo</th>
    <td>7</td>
    <td>7,223</td>
  </tr>
  <tr>
    <th scope="row">Mia Oolong</th>
    <td>9</td>
    <td>6,219</td>
  </tr>
</table> */}
    </div>
  )
}

export default OS;