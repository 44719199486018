
const NewNotify = ({ message, messageType }: { message: string | null, messageType: 'success' | 'error' | null},) => {
    if (!message) {
        return null
    }
    
    return (
        <div>
            <div style={{
        //textAlign: 'center',
        color: messageType === 'success' ? '#2eb872' : 'red',
        //padding: '10px', // Optional: Add padding for better appearance
        // animationDelay: '5s',
        // marginLeft: '20%',
        // marginRight: '20%'
    }}>
        {/* {message} */}
                {/* {message.split('\n').map((line, index) => (
                <p key={index} style={{ margin: 0 }}>{line}</p> */}

{message.split('\n').map((line, index) => (
                <p key={index} style={{ margin: '10px 0' }}>{line}</p>
            ))}
    </div>
        </div>
    );

}

export default NewNotify;