import { useRef, useEffect, SyntheticEvent, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Editor } from './Editor/Editor';

import { $getRoot, LexicalEditor } from 'lexical';
import { BookReviewType } from '../types';
import { ALL_BOOKREVIEWS, DELETE_BOOKREVIEW, EDIT_BOOKREVIEW } from '../queries';
import { useNavigate } from 'react-router-dom';

const EditBookReview = ({ setNotification, bookReview, setOpenEditor }:
  { setNotification: (message: string, type: 'success' | 'error') => void, bookReview: BookReviewType, setOpenEditor: React.Dispatch<React.SetStateAction<boolean>> }) => {

  const [bookTitle, setBookTitle] = useState('');
  const [reviewTitle, setReviewTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const navigate = useNavigate()

  const [editBookReview, result] = useMutation(EDIT_BOOKREVIEW, {
    onError: (error) => {
      //const messages = error.graphQLErrors[0].message
      setNotification(error.message, 'error');
    },
  })
  const [deleteBookReview] = useMutation(DELETE_BOOKREVIEW, {
    refetchQueries: [{ query: ALL_BOOKREVIEWS }],
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })
  const editorRef = useRef<LexicalEditor | null>(null);

  const submit = (event: SyntheticEvent) => {
    event.preventDefault()
    const latestEditorState = editorRef.current?.getEditorState();
    const textContent = latestEditorState?.read(() =>
      $getRoot().getTextContent()
    );
    const description = textContent;
    
    editBookReview({
      variables: {
        id: bookReview.id, author: author, bookTitle: bookTitle, reviewTitle: reviewTitle,
        description: description, imageUrl: imageUrl
      }
    })

    if (!result.error) {
      setOpenEditor(false);
    }
    setNotification('추가되었습니다.', 'success');
  }

  const submitDeleteBookReview = async (id: number) => {
    const result = await deleteBookReview
      ({
        variables: {
          id: id
        }
      })

    if (result.data) setNotification('삭제되었습니다.', 'success');

    navigate('/bookreviews')
  }

  useEffect(() => {
    if (result.data && result.data.editDescription === null) {
      setNotification('Not Found', 'error')
    }
    setBookTitle(bookReview.bookTitle.toString())
    setReviewTitle(bookReview.reviewTitle.toString())
    setAuthor(bookReview.author.toString())
    setImageUrl(bookReview.imageUrl.toString())
  }, [result.data]) // eslint-disable-line 

  return (
    <div>
      <h2>{bookReview.bookTitle} 서평 수정하기</h2>

      <form onSubmit={submit}>
        <div>
          책 제목 <input
            value={bookTitle}
            onChange={({ target }) => setBookTitle(target.value)}
          />
        </div>      
        <div>
          리뷰 제목 <input
            value={reviewTitle}
            onChange={({ target }) => setReviewTitle(target.value)}
          />
        </div>        
        <div>
          저자 <input
            value={author}
            onChange={({ target }) => setAuthor(target.value)}
          />
        </div>
        <div className="editorWrapper">
          {/* <input
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
          /> */}
          <Editor ref={editorRef} description={bookReview.description} />
        </div>
        <div>
          링크 주소 <input
            value={imageUrl}
            onChange={({ target }) => setImageUrl(target.value)}
          />
        </div>
        <button type='submit'>수정</button>
      </form>
      <button type='button' onClick={() => setOpenEditor(false)}>취소</button>
      <hr />
      <button onClick={() => submitDeleteBookReview(bookReview.id)}>{"삭제"}</button>
      <hr />
    </div>
  )
}

export default EditBookReview;